import { useMemo, useEffect } from 'react'
import { useQuery } from 'apollo-client'
import dayjs from 'date'
import { useFt } from 'hooks'
import { constants } from 'helpers'
import logger from 'logger'
import config from 'config'

import { useUser } from 'modules/user'
import { priceChannels } from 'modules/priceSelection'

import limitedDropSaleQuery from './graph/limitedDropSale.graphql'
import limitedDropSettingsStrapiQuery from './graph/limitedDropSettings.strapi.graphql'

import { getCountdownData, getAnalyticMetadata, getEntryPointsData } from './util'


type UseLimitedDropSaleProps = {
  uid?: string
  detailed?: boolean
  skip?: boolean
}

export const useLimitedDropSale = (props?: UseLimitedDropSaleProps) => {
  const { uid, detailed = false, skip } = props || {}
  const isCurrentSale = !Boolean(uid)

  const isLimitedDropEnabled = useFt(constants.features.limitedDrops)

  const { country, dateTime } = useUser()

  const nowDate = dayjs(dateTime)
  const requestNowDate = nowDate.startOf('hour').utc().format()

  // TODO: Migrate to exact uid of campaign instead of date ranges after CRM changes — added on 09–08–2024 by algeas
  const filters = [
    { or: [ { startsFrom: { null: true } }, { startsFrom: { lte: requestNowDate } } ] },
    { or: [ { endsAfter: { null: true } }, { endsAfter: { gte: requestNowDate } } ] },
  ]

  const { data, isFetching, error } = useQuery(limitedDropSaleQuery, {
    fetchPolicy: 'cache-first',
    skip: skip || !isLimitedDropEnabled,
    variables: {
      uid: uid || '',
      priceSelection: {
        country,
        channel: priceChannels.limitedDropSale,
      },
      detailed,
      exact: !isCurrentSale,
    },
    context: {
      ssrCache: true,
      ssrCacheTTL: 60,
    },
  })
  const { data: strapiData, isFetching: isStrapiFetching, error: strapiError } = useQuery(limitedDropSettingsStrapiQuery, {
    fetchPolicy: 'cache-first',
    client: 'strapi',
    skip: skip || !isLimitedDropEnabled,
    variables: {
      filters: {
        and: filters,
      },
    },
    context: {
      ssrCache: true,
      ssrCacheTTL: 60,
    },
  })

  const saleData = isCurrentSale ? data?.limitedDropSaleCurrent?.data : data?.limitedDropSale?.data
  const campaignRules = strapiData?.limitedDrops?.data?.[0]?.attributes

  useEffect(() => {
    if (
      saleData
      // Avoid false errors on stage and preprod
      && config.runEnv === 'prod'
      && saleData.status !== 'FINISHED'
      && !isStrapiFetching
      && !campaignRules?.bannerFragments?.length
      && !campaignRules?.heroBannerFragments?.length
      && !campaignRules?.landingPageFragments?.length
      && !campaignRules?.modalFragments?.length
      && !campaignRules?.navigationLinkFragments?.length
      && !campaignRules?.topBarBannerFragments?.length
    ) {
      logger.error('Strapi entry points data for Limited Drop is not found')
    }
  }, [ saleData, campaignRules, isStrapiFetching ])

  const modifiedData = useMemo<LimitedDropSale.Payload>(() => {
    if (!saleData) {
      return null
    }

    const startDate = campaignRules?.countdownStartsFrom || saleData.startDate
    const endDate = campaignRules?.countdownEndsAfter || saleData.endDate
    const entryPointsSoldOutStrategy = campaignRules?.entryPointsSoldOutStrategy || 'out_of_stock'

    const analyticMetadata = getAnalyticMetadata(saleData)
    const { entryPointsUsageMap, entryPointsMap } = getEntryPointsData({ nowDate, campaignRules })
    const { countdownTimeLeft, isCountdownStarted, isCountdownExpired } = getCountdownData({ nowDate, startDate, endDate })

    const isSoldOutByStatus = saleData.status === 'SOLD_OUT' || saleData.status === 'FINISHED'
    const isSoldOutByCountdown = entryPointsSoldOutStrategy === 'countdown_expired' && isCountdownExpired

    const isSoldOut = isSoldOutByStatus || isSoldOutByCountdown

    const withCountdown = isCountdownStarted && !isSoldOut
    const withLandingPage = !isCurrentSale || entryPointsUsageMap.landingPage !== undefined

    return {
      // Raw data
      saleData,
      // Countdown data
      countdownTimeLeft,
      isCountdownStarted,
      isCountdownExpired,
      // Entry points visibility and settings
      entryPointsMap,
      entryPointsUsageMap,
      entryPointsSoldOutStrategy,
      // Analytic metadata
      analyticMetadata,
      // Calculated flags (can be overridden by local components' logic)
      isSoldOut,
      isCurrentSale,
      withCountdown,
      withLandingPage,
    }
  }, [ nowDate, saleData, campaignRules, isCurrentSale ])

  return {
    data: modifiedData,
    error,
    isFetching,
  }
}

