import { useMemo } from 'react'
import links from 'links'

import { navMessages } from 'compositions/navigation'

import { useLimitedDropSale } from './useLimitedDropSale'


const MESSAGES_MAP = {
  ComponentLimitedDropDefaultNavigationLink: navMessages.limitedDrop,
  ComponentLimitedDropVaultNavigationLink: navMessages.limitedDropVault,
}

type UseLimitedDropNavigationItemProps = {
  placement: 'footer' | 'header'
}

export const useLimitedDropNavigationItems = ({ placement }: UseLimitedDropNavigationItemProps) => {
  const { data: limitedDropData } = useLimitedDropSale()

  const limitedDropNavigationLinkFragmentIdsMap = limitedDropData?.entryPointsUsageMap?.navigationLink

  const navigationItems = useMemo<Record<LimitedDropSale.NavigationLinkPlacement, Navigation.ItemBase[]>>(() => {
    const navigationLinkEntries = Object.entries(limitedDropNavigationLinkFragmentIdsMap || {}).map(([ placement, fragments ]) => {
      const components = (fragments || []).map<Navigation.ItemBase>((fragmentId) => {
        const fragment = limitedDropData?.entryPointsMap?.[fragmentId]

        if (!fragment) {
          return null
        }

        const { __typename, withNewLabel } = fragment

        const title = MESSAGES_MAP[__typename]

        if (!title) {
          return null
        }

        return {
          id: `${placement}-${placement.toLowerCase()}-limitedDrop`,
          title,
          to: links.currentLimitedDrop,
          label: placement !== 'footer' && withNewLabel && navMessages.labels.new,
        }
      }).filter(Boolean)

      return [ placement, components ]
    })

    return Object.fromEntries(navigationLinkEntries)
  }, [ limitedDropData?.entryPointsMap, limitedDropNavigationLinkFragmentIdsMap ])

  return navigationItems
}
